/* Lite-Youtube */
const youtubePlayButton = document.querySelector(".lty-playbtn");
youtubePlayButton.addEventListener('click', () => {
  youtubePlayButton.classList.add("remove");
});


/* swiper */
const swiper = new Swiper('.firstview-swiper-container', {
    loop: true,
    speed: 3000,
    slidesPerView: 1.6,
    spaceBetween: 24,
    autoplay: {
        delay: 0,
        disableOnInteraction: false,
    },
    breakpoints: {
        2000: {
            slidesPerView: 4.5,
            spaceBetween: 64,
        },
        1500: {
            slidesPerView: 3.5,
            spaceBetween: 54,
        },
        1200: {
            slidesPerView: 3,
            spaceBetween: 40,
        },
        834: {
            slidesPerView: 2.4,
            spaceBetween: 40,
        },
    }
});

const typesSwiper = new Swiper('.types-swiper-container', {
    loop: true,
    speed: 3000,
    slidesPerView: 1.6,
    spaceBetween: 24,
    autoplay: {
        delay: 0,
        disableOnInteraction: false,
    },
    breakpoints: {
        834: {
            slidesPerView: 3,
            spaceBetween: 40,
        },
    }
});

const worksSwiper = new Swiper('.works-swiper-container', {
    loop: true,
    speed: 1000,
    slidesPerView: 1.6,
    spaceBetween: 24,
    navigation: {
        nextEl: ".swiper-button-next",
    },
    breakpoints: {
        540: {
            slidesPerView: 2.6,
            spaceBetween: 40,
        }
    }
});

const blogsSwiper = new Swiper(".blogs-swiper-container", {
    loop: true,
    speed: 1000,
    slidesPerView: 1.6,
    spaceBetween: 32,
    navigation: {
        nextEl: '.blog-button-next',
        prevEl: '.blog-button-prev',

    },
    breakpoints: {
        2000: {
            slidesPerView: 3.4,
        },
        1250: {
            slidesPerView: 2.4,
        },
        834: {
            slidesPerView: 1.8,
        },
        540: {
            slidesPerView: 2.4,
        }
    }
});
